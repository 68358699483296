import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { BgImage } from "gbimage-bridge";
import parse from "html-react-parser";
import React from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { BsArrowRightShort } from "react-icons/bs";
import Layout from "../components/layout";

const BlogPostTemplate = ({ data: { post, site, otherPosts, blogImg } }) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Case Studies",
				item: {
					url: `${siteUrl}/case-studies`,
					id: `${siteUrl}/case-studies`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/case-studies/${post.slug}`,
					id: `${siteUrl}/case-studies/${post.slug}`,
				},
			},
		],
	};

	const pluginImage = getImage(
		post.caseStudyFields.featuredImage.localFile.childImageSharp.gatsbyImageData
	);
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${post.title} | Cranfield IT Solutions`}
				description={post?.excerpt}
				language="en"
				openGraph={{
					type: "article",
					url: `${siteUrl}/case-studies/${post.slug}`,
					title: `${post.title} | Cranfield IT Solutions`,
					description: `${post?.excerpt}`,
					images: [
						{
							url: `${post.caseStudyFields.featuredImage.localFile.publicURL}`,
							width: `${post.caseStudyFields.featuredImage.localFile.childImageSharp.original.width}`,
							height: `${post.caseStudyFields.featuredImage.localFile.childImageSharp.original.height}`,
							alt: `${post.caseStudyFields.featuredImage.node?.altText}`,
						},
					],
				}}
			/>

			<section className="position-relative">
				<BgImage
					className="h-100 w-100 py-5 py-lg-7 position-relative "
					image={pluginImage}
					alt=""
				>
					<div
						style={{ opacity: 0.5, zIndex: 1 }}
						className="w-100 h-100 position-absolute start-0 top-0 bg-black"
					></div>
					<Container className="position-relative" style={{ zIndex: 2 }}>
						<Row className="justify-content-center">
							<Col className="text-center" lg={8}>
								<h2 className="text-light-grey d-none d-lg-block pb-4 fs-3">
									{post.dateGmt}
								</h2>
								<h2 className="text-light-grey d-lg-none fs-5">
									{post.dateGmt}
								</h2>
								<h1 className="text-white display-4">{post.title}</h1>

								{/* <Button
									as={Link}
									to="/contact-us"
									variant="primary"
									className="px-5 mt-xl-10 mt-4 mb-3 mb-md-0  py-3 w-100 w-md-auto"
								>
									Book a sales call
								</Button> */}
								<a
									className="btn btn-primary px-5 mt-xl-5 mt-4 mb-3 mb-md-0 me-md-3 py-3 w-100 w-md-auto"
									href="https://outlook.office.com/bookwithme/user/1386c77a23fa477c8c1f8232294da53c@cranfielditsolutions.co.uk/meetingtype/OHHZY3rg40-mG-U4B1-gjw2?anonymous&ep=mLinkFromTile"
									target="_blank"
									rel="noopener noreferrer"
								>
									Book a sales call
								</a>
							</Col>
						</Row>
					</Container>
				</BgImage>
			</section>
			<section className="position-relative py-5 py-lg-7 ">
				<Container className=" ">
					<Row className="justify-content-center">
						<Col lg={10} xl={8}>
							<div className="blog-content  ">{parse(post.content)}</div>
							<div className="d-flex justify-content-end py-3 align-items-center">
								<Link to="/case-studies" className="blue-link roboto-medium">
									Read more
									<BsArrowRightShort className="fs-2" />
								</Link>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query PostById($id: String!) {
		post: wpCaseStudy(id: { eq: $id }) {
			id
			excerpt
			slug
			content
			title
			dateGmt(formatString: "D MMMM yyyy")
			caseStudyFields {
				featuredImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								quality: 100
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
